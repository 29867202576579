<template>

  <div class="checkout-items">
    <b-card
      v-for="item in items"
      :key="item.id"
      class="ecommerce-card"
      no-body
    >

      <!-- item Image -->
      <div class="item-img">
        <b-link>
          <b-img
            :src="item.image"
            :alt="`${item.name}-${item.id}`"
          />
        </b-link>
      </div>

      <!-- item Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ item.name }}
            </b-link>
          </h6>
          <!-- <span class="item-company">By <b-link class="company-name">{{ item.brand }}</b-link></span> -->
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-25': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= item.rating}, star <= item.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div>
        </div>
        <!-- <span class="text-success mb-1">In Stock</span> -->
        <div class="item-quantity">
          <span class="quantity-title">Qty:</span>
          <b-form-spinbutton
            v-model="item.uds"
            min="1"
            size="sm"
            class="ml-75"
            inline
            @change="changeUdsItem(item)"
          />
        </div>
        <!-- <span class="delivery-date text-muted">Delivery by {{ formatDate(item.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>
        <span class="text-success">{{ item.discountPercentage }}% off {{ item.offers }} offers Available</span> -->
      </b-card-body>

      <!-- item Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              {{ item.price * item.uds }} €
            </h4>
            <!-- <p
              v-if="item.hasFreeShipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
              >
                Free Shipping
              </b-badge>
            </p> -->
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeItemFromCart(item.id)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Remove</span>
        </b-button>
        <!-- <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="toggleitemInWishlist(item)"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{'fill-current': item.isInWishlist}"
          />
          <span class="text-nowrap">Wishlist</span>
        </b-button> -->
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BFormSpinbutton,
} from 'bootstrap-vue'
// import store from '@/store'
// import { ref } from '@vue/composition-api'
// import { formatDate } from '@core/utils/filter'
import { useCartItems } from '../useShop'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BFormSpinbutton,
  },
  setup() {
    const {
      items,
      fetchCartItems,
      addItems,
      totalAmount,
      removeItemFromCart,
      changeUdsItem,
    } = useCartItems()

    fetchCartItems()

    return {
      fetchCartItems,
      items,
      totalAmount,
      addItems,
      removeItemFromCart,
      changeUdsItem,
    }
  },
}
</script>

<style>

</style>
